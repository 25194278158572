import React from "react"
import styled from "styled-components"

import { MEDIA_MOBILE } from "../const"
import Layout from "../components/layout"
import Metadata from "../components/metadata"
import Heading2 from "../components/heading2"

interface Props {
  location: Location
}
const Error404: React.FC<Props> = ({ location }) => (
  <Layout>
    <Metadata title="404 NOT FOUND" pagePath={location.pathname} />
    <Wrapper>
      <Heading2>404 NOT FOUND</Heading2>
    </Wrapper>
  </Layout>
)

export default Error404

const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: 40em;
  margin: 40px auto;

  @media (${MEDIA_MOBILE}) {
    width: 100%;
    padding: 0 20px;
  }
`
